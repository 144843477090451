/**
 * script tag に関するヘルパークラス
 */
export class ScriptTagUtil {
  /** トラッキングIDのデータ属性 */
  static readonly ATTRIBUTE_TRACKING_ID = 'data-tid';
  /** HTMLElement Typeguard */
  static hasDataTrackigIdElement(ele: any): ele is HTMLElement {
    return ele !== null && ele !== undefined && ele.getAttribute !== undefined && ele.getAttribute('data-tid') !== null;
  }

  /**
   * sdk を src 指定している script タグを取得する
   * document.currentScript が使用出来ない場合は data 属性で判定する
   */
  static getCurrentScript(): HTMLElement | null {
    if (document.currentScript !== undefined) {
      if (this.hasDataTrackigIdElement(document.currentScript)) {
        return document.currentScript;
      }
    } else {
      const selected = document.querySelector(`script[${this.ATTRIBUTE_TRACKING_ID}]`);
      if (this.hasDataTrackigIdElement(selected)) {
        return selected;
      }
    }
    return null;
  }

  /**
   * sdk script タグに指定されている tracking id を取得する
   */
  static getTrackingId(): string | null {
    const currentScript = this.getCurrentScript();
    if (currentScript !== null) {
      return currentScript.getAttribute(this.ATTRIBUTE_TRACKING_ID);
    }
    return null;
  }
}
