import { PostMessageUtil, UnlimMessageOrigin, UnlimPostMessage } from '../../common/util/post-message-util';

import { ScriptTagUtil } from './utils/script-tag-util';
import { IframeUrlArgs, IframeUtil, ModalInfo } from './utils/iframe-util';

class Main {
  trackingId: string | null = null;
  modalInfo: ModalInfo | null = null;

  constructor() {
    // `<script>` に指定されている `data-tid` の値を取り出す
    const tid = ScriptTagUtil.getTrackingId();

    if (tid === null) {
      console.warn('missing parameter: tracking id');
      return;
    }
    const title = document.title;
    const iframeArgs: IframeUrlArgs = {
      baseUrl: PostMessageUtil.getModalSiteHost(),
      trackingId: tid,
      originUrl: window.location.href,
      title
    };

    // 現在のURLに含まれるURLクエリ文字列からシェアIDを取り出す
    const shareId = IframeUtil.getShareIdFromSearchString(window.location.search);
    if (shareId) {
      iframeArgs.shareId = shareId;
    }

    // 現在の記事には複数のウィジェットを表示させる可能性があるので、それらを表示させるための iframe の id を格納する `Set` を用意する
    const loadedIframeIds: Set<string> = new Set();

    // 現在のDOMからウィジェット表示対象となる `<ins>` 要素を全て探し、 ウィジェットを表示させるための `<iframe>` を埋め込む
    const insElements = IframeUtil.embedIframes(iframeArgs);

    // ウィジェットをクリックされた際は、対象となるアスリートのモーダルを表示させる必要があるため、
    // モーダルを構成する要素を `<body>` の中に埋め込んでおく
    const openModal = IframeUtil.addModalWrapper();

    // ウィジェット側からmessageが送られた際の処理を event listener に登録
    window.addEventListener('message', (event) => {
      // ウィジェットからモーダルオープンを示すmessageが送られた場合は、指定された選手のモーダルを表示する
      const openPlayerId = PostMessageUtil.getOpenModalPlayerId(event);
      if (openPlayerId) {
        this.modalInfo = openModal(iframeArgs, openPlayerId);
        return;
      }

      // ウィジェットからエラー発生を示すmessageが送られた場合は、その iframe を閉じる
      const loadErrorId = PostMessageUtil.getBannerErrorTarget(event);
      if (loadErrorId) {
        IframeUtil.shrinkErrorIframe(loadErrorId);
        return;
      }

      // ウィジェットからロード完了を示すmessageが送られた場合は、その iframe の id を保存する
      const bannerLoadId = PostMessageUtil.getBannerLoadedTarget(event);
      if (bannerLoadId) {
        loadedIframeIds.add(bannerLoadId);
        this.detectedInViewport(insElements, loadedIframeIds);
      }

      if (PostMessageUtil.isTargetMessage(event, UnlimMessageOrigin.WidgetSite, UnlimPostMessage.ShareUrlRequest)) {
        if (this.modalInfo) {
          const href = window.location.href;
          const ogUrl = document.head.querySelector('meta[property="og:url"]');
          const shareUrl = ogUrl ? ogUrl.getAttribute('content') || href : href;

          // モーダル内でシェア操作をした場合は記事のURLをシェアする必要があるため、messageを介してこの記事のURLをモーダル側に渡す
          this.modalInfo.window.postMessage(`${UnlimPostMessage.ShareUrlSend}${encodeURIComponent(shareUrl)}`, this.modalInfo.url);
        }
      }
    });

    // ウィジェットがブラウザの viewport 内に表示された場合は view 数をカウントアップする必要があるため、
    // スクロール時にウィジェットが表示されたかどうかをチェックする処理をバインドする
    window.addEventListener('scroll', () => {
      if (insElements.size > 0) {
        window.requestAnimationFrame(() => {
          this.detectedInViewport(insElements, loadedIframeIds);
        });
      }
    });
  }

  detectedInViewport(insElements: Set<HTMLElement>, loadedIframeIds: Set<string>): void {
    // 現在の viewport 内に表示されているウィジェットのリストを `Set` で取得する
    const visiblePoint = IframeUtil.detectWidgetScrollIn(insElements, window);
    if (visiblePoint) {
      const itr = visiblePoint.values();
      for (let i = 0; i < visiblePoint.size; i++) {
        const ele = itr.next().value as HTMLElement;
        const iframe = ele.querySelector('iframe');
        if (iframe && iframe.contentWindow) {
          const iframeId = iframe.getAttribute(IframeUtil.DATA_IFRAME_ID);
          if (iframeId && loadedIframeIds.has(iframeId)) {
            iframe.contentWindow.postMessage(UnlimPostMessage.WidgetScrollIn, PostMessageUtil.getModalSiteHost());

            // ウィジェットview数を一度カウントアップしたら、そのウィジェットは今後のチェック対象から外す
            insElements.delete(ele);
            loadedIframeIds.delete(iframeId);
            // TODO: 全てのウィジェットのimpression APIコールがされた際は、scrollイベントからハンドラを削除する
            // https://github.com/xflagstudio/no12-profile-widget-client/pull/38/files#r360290289
          }
        }
      }
    }
  }
}

new Main(); // eslint-disable-line
