import { SHARE_ID_QUERY_NAME } from '../../../common/util/common-share-util';
import { PostMessageUtil, UnlimPostMessage } from '../../../common/util/post-message-util';
/**
 * iframe src url の生成引数
 * @property {string} baseUrl ベース URL 末尾に / を含まない
 * @property {string} trackingId CP に付与されるトラッキング ID
 * @property {string} originUrl SDK が embed されている CP Site の location
 * @property {string} title SDK が embed されている CP Site の title
 */
export interface IframeUrlArgs {
  baseUrl: string;
  trackingId: string;
  originUrl: string;
  title: string;
  shareId?: string;
}
/** iframe size */
export interface IframeSize {
  width: string;
  height: string;
}
/** Modal Window Info */
export interface ModalInfo {
  /** モーダルを表示するための `<iframe>` 内の `window` オブジェクト */
  window: Window;
  /** モーダルを表示するための `<iframe>` の `src` で指定されるURL */
  url: string;
}

/**
 * iframe に関するヘルパークラス
 */
export class IframeUtil {
  /** iframe 挿入対象の select query */
  static readonly IFRAME_PARENT_SELECTOR = 'ins.unlim-widget';
  /** 対象プレイヤー ID Attribute */
  static readonly DATA_PLAYER_ID = 'data-player-id';
  /** iframe ID Attribute */
  static readonly DATA_IFRAME_ID = 'data-iframe-id';
  /** iframe size Attribute */
  static readonly DATA_SIZE = 'data-size';
  /** iframe size regexp */
  static readonly SIZE_CONVERT_REGEXP = /^(\d*)x(\d*)$/;
  static readonly SIZE_REGEXP_WIDTH_POSITION = 1;
  static readonly SIZE_REGEXP_HEIGHT_POSITION = 2;
  static readonly SIZE_REGEXP_MATCH_LENGTH = 3;
  /** iframe sandbox options */
  static readonly SANDBOX_OPTIONS = 'allow-forms allow-scripts allow-same-origin allow-modals allow-popups allow-popups-to-escape-sandbox allow-top-navigation-by-user-activation';
  /** banner iframe style */
  static readonly BANNER_IFRAME_STYLE = ['border: 0', 'border-radius: 8px', 'box-shadow: 0 3px 6px rgba(0,0,0,0.16)', 'overflow: hidden'].join(';');
  /** iframe に付与するランダム ID の元 ( 0-9 + alphabet ) */
  static readonly IFRAME_ID_RANDOM_CHARACTORS = 36;
  /** display none */
  static readonly MODAL_DISPLAY_NONE = 'none';
  /** Modal wrapper style */
  static readonly MODAL_WRAPPER_STYLE = [
    'display: none',
    'position: fixed',
    'top: 0',
    'left: 0',
    'width: 100%',
    'height: 100%',
    'justify-content: center',
    'align-items: center',
    'z-index: 10000'
  ].join(';');

  /** Modal wrapper active display value */
  static readonly MODAL_WRAPPER_DISPLAY_ACTIVE = 'flex';
  static readonly MODAL_CLOSE_BUTTON_LEFT_STYLE = [
    'display: inline-block',
    'position: absolute',
    'top: 8px',
    'left: 0',
    'width: 22px',
    'height: 2px',
    'background-color: #fff',
    'transform: rotate(45deg)'
  ].join(';');

  static readonly MODAL_CLOSE_BUTTON_RIGHT_STYLE = [
    'display: inline-block',
    'position: absolute',
    'top: 8px',
    'left: 0',
    'width: 22px',
    'height: 2px',
    'background-color: #fff',
    'transform: rotate(-45deg)'
  ].join(';');

  /** Modal close button style */
  static readonly MODAL_CLOSE_BUTTON_STYLE = [
    'display: inline-block',
    'position: absolute',
    'top: -25px',
    'right: 15px',
    'width: 20px',
    'height: 20px',
    'background-color: transparent;',
    'border: none;'
  ].join(';');

  /** Modal bg css class */
  static readonly MODAL_BG_CLASS = 'unlim-modal-bg';
  /** Modal bg style */
  static readonly MODAL_BG_STYLE = ['position: absolute', 'top: 0', 'left: 0', 'width: 100%', 'height: 100%', 'transition: background-color 300ms', 'background-color: transparent'].join(';');
  /** Modal bg initial color */
  static readonly MODAL_BG_INITIAL_COLOR = 'transparent';
  /** Modal bg active color */
  static readonly MODAL_BG_ACTIVE_COLOR = 'rgba(0,0,0,0.7)';
  /** Modal iframe wrapper style */
  static readonly MODAL_IFRAME_WRAPPER_STYLE = ['display: inline-block', 'position: relative'].join(';');
  /** Modal iframe style */
  static readonly MODAL_IFRAME_STYLE = [
    'display: none',
    'position: relative',
    'max-height: 0',
    'opacity: 0',
    'transition: max-height 300ms ease-in-out',
    'transition-property: max-height, opacity',
    'border: 0',
    'border-radius: 16px'
  ].join(';');

  /** max-width of Modal iframe  */
  static readonly MODAL_MAX_WIDTH = 400;
  /** max-width of Modal iframe  */
  static readonly MODAL_MAX_HEIGHT = 596;
  /** horizontal padding of Modal iframe  */
  static readonly MODAL_HORIZONTAL_PADDING = 40;
  /** vertical padding of Modal iframe  */
  static readonly MODAL_VERTICAL_PADDING = 80;
  /** CSS Animation start delaay */
  static readonly CSS_ANIMAITON_START_DELAY = 50;

  /** iframe insert target Typeguard */
  static isWidgetTargetTagElement(ele: any): ele is HTMLElement {
    return ele !== null && ele !== undefined && ele.getAttribute !== undefined && ele.getAttribute(this.DATA_PLAYER_ID) !== null && ele.getAttribute(this.DATA_SIZE) !== null;
  }

  /** iframe typeguard */
  static isIframe(ele: any): ele is HTMLIFrameElement {
    return ele !== null && ele !== undefined && ele.nodeName === 'IFRAME';
  }

  /**
   * IframeUrlArgs と対象パスを受け取り、URL フルパスを生成する
   * IframeUrlArgs のうち、 trackingId と originUrl は query として付与する
   * @param urlArgs IframeUrlArgs
   * @param path 対象パス 先頭に / を含む
   * @param iframeId iframe に付与する ID。バナーに使用する
   * @returns url string
   */
  static generateUrl(urlArgs: IframeUrlArgs, path: string, iframeId?: string): string {
    let urlBase = `${urlArgs.baseUrl}${path}?provider_id=${urlArgs.trackingId}&url=${encodeURIComponent(urlArgs.originUrl)}&title=${encodeURIComponent(urlArgs.title)}`;

    if (urlArgs.shareId) {
      urlBase = `${urlBase}&${SHARE_ID_QUERY_NAME}=${urlArgs.shareId}`;
    }

    if (iframeId) {
      return `${urlBase}&iframe_id=${iframeId}`;
    } else {
      return urlBase;
    }
  }

  /**
   * embed 対象の class を取得し、 iframe を挿入する
   * @param urlArgs IframeUrlArgs
   * @returns `iframe` を格納する `ins` 要素のリスト
   */
  static embedIframes(urlArgs: IframeUrlArgs): Set<HTMLElement> {
    const list = document.querySelectorAll(this.IFRAME_PARENT_SELECTOR);
    const insElements: Set<HTMLElement> = new Set();
    for (let i = 0; i < list.length; i++) {
      const ele = list.item(i);
      if (this.isWidgetTargetTagElement(ele)) {
        const playerId = ele.getAttribute(this.DATA_PLAYER_ID);
        const size = ele.getAttribute(this.DATA_SIZE) || '';
        const iframeId = Math.random().toString(this.IFRAME_ID_RANDOM_CHARACTORS).substring(2);
        const url = this.generateUrl(urlArgs, `/athletes/${playerId}`, iframeId);
        const iframe = this.generateIframe(url, size);
        iframe.setAttribute('style', this.BANNER_IFRAME_STYLE);
        iframe.setAttribute(this.DATA_IFRAME_ID, iframeId);
        iframe.setAttribute('scrolling', 'no');
        ele.appendChild(iframe);
        insElements.add(ele);
      }
    }
    return insElements;
  }

  /**
   * エラーが起きた iframe を畳む
   * @param targetId
   */
  static shrinkErrorIframe(targetId: string): void {
    const list = document.querySelectorAll(this.IFRAME_PARENT_SELECTOR);
    for (let i = 0; i < list.length; i++) {
      const ele = list.item(i);
      if (this.isWidgetTargetTagElement(ele)) {
        const iframe = ele.querySelector('iframe') as HTMLIFrameElement;
        const iframeId = iframe.getAttribute(this.DATA_IFRAME_ID);
        if (iframeId === targetId) {
          if (iframe) {
            iframe.setAttribute('style', 'border:0;');
            iframe.setAttribute('height', '0');
          }
        }
      }
    }
  }

  /**
   * data-size 属性の値を IframeSize に変換する
   * @param sizeString
   */
  static generateIframeSize(sizeString: string): IframeSize {
    const iframeSize: IframeSize = { width: '0', height: '0' };
    const matchArray = sizeString.match(this.SIZE_CONVERT_REGEXP);
    if (matchArray && matchArray.length === this.SIZE_REGEXP_MATCH_LENGTH) {
      iframeSize.width = matchArray[this.SIZE_REGEXP_WIDTH_POSITION];
      iframeSize.height = matchArray[this.SIZE_REGEXP_HEIGHT_POSITION];
    } else {
      console.warn(`Given data-size attribute "${sizeString}" is invalid.`);
    }
    return iframeSize;
  }

  /**
   * ウィジェットが Viewport 内に現れたかを判定し、現れたものの一覧を返す
   * @param insElements Set<HTMLElement>
   * @returns visiblePoints Viewport 内に入った要素リスト
   */
  static detectWidgetScrollIn(insElements: Set<HTMLElement>, window: Window): Set<HTMLElement> | null {
    const visiblePoints: Set<HTMLElement> = new Set();
    const itr = insElements.values();
    for (let i = 0; i < insElements.size; i++) {
      const ele = itr.next().value as HTMLElement;
      const rect = ele.getBoundingClientRect();
      if (rect.bottom > 0 && rect.top < window.innerHeight) {
        visiblePoints.add(ele);
      }
    }
    return visiblePoints.size > 0 ? visiblePoints : null;
  }

  /**
   * モーダルを document の末尾に追加して、モーダルのオープン関数を返す
   * @returns {(args: IframeUrlArgs, playerId: string) => void} モーダルコントロール関数
   */
  static addModalWrapper(): (args: IframeUrlArgs, playerId: string) => ModalInfo | null {
    let isModalOpen = false;
    const modalWrapper = document.createElement('div');
    modalWrapper.setAttribute('style', this.MODAL_WRAPPER_STYLE);

    const modalBg = document.createElement('div');
    modalBg.classList.add(this.MODAL_BG_CLASS);
    modalBg.setAttribute('style', this.MODAL_BG_STYLE);
    modalBg.addEventListener('transitionend', () => {
      const hasBgColor = modalBg.style.backgroundColor !== this.MODAL_BG_INITIAL_COLOR;
      if (!hasBgColor) {
        modalWrapper.style.display = this.MODAL_DISPLAY_NONE;
      }
      isModalOpen = hasBgColor;
    });

    modalWrapper.appendChild(modalBg);

    const closeButton = document.createElement('span');
    const closeButtonElemLeft = document.createElement('span');
    const closeButtonElemRight = document.createElement('span');
    closeButton.setAttribute('style', this.MODAL_CLOSE_BUTTON_STYLE);
    closeButtonElemLeft.setAttribute('style', this.MODAL_CLOSE_BUTTON_LEFT_STYLE);
    closeButtonElemRight.setAttribute('style', this.MODAL_CLOSE_BUTTON_RIGHT_STYLE);
    closeButton.appendChild(closeButtonElemLeft);
    closeButton.appendChild(closeButtonElemRight);

    const iframeWrapper = document.createElement('div');
    iframeWrapper.setAttribute('style', this.MODAL_IFRAME_WRAPPER_STYLE);
    const iframe = document.createElement('iframe');
    iframe.setAttribute('sandbox', this.SANDBOX_OPTIONS);
    iframe.setAttribute('style', this.MODAL_IFRAME_STYLE);
    iframe.addEventListener('load', () => {
      iframe.style.display = 'block';
      iframe.setAttribute('width', '' + Math.min(this.MODAL_MAX_WIDTH, window.innerWidth - this.MODAL_HORIZONTAL_PADDING));
      iframe.setAttribute('height', '' + Math.min(this.MODAL_MAX_HEIGHT, window.innerHeight - this.MODAL_VERTICAL_PADDING));
      setTimeout(() => {
        iframe.style.maxHeight = '100%';
        iframe.style.opacity = '1';
      }, this.CSS_ANIMAITON_START_DELAY);
    });
    iframeWrapper.appendChild(closeButton);
    iframeWrapper.appendChild(iframe);
    modalWrapper.appendChild(iframeWrapper);
    window.addEventListener('resize', () => {
      if (isModalOpen) {
        iframe.setAttribute('width', '' + Math.min(this.MODAL_MAX_WIDTH, window.innerWidth - this.MODAL_HORIZONTAL_PADDING));
        iframe.setAttribute('height', '' + Math.min(this.MODAL_MAX_HEIGHT, window.innerHeight - this.MODAL_VERTICAL_PADDING));
      }
    });

    closeButton.addEventListener('click', () => {
      closeModal();
    });

    modalBg.addEventListener('click', () => {
      closeModal();
    });

    const closeModal = (): void => {
      if (isModalOpen) {
        if (iframe.contentWindow) {
          iframe.contentWindow.postMessage(UnlimPostMessage.CloseModal, PostMessageUtil.getModalSiteHost());
        }
        modalBg.style.backgroundColor = this.MODAL_BG_INITIAL_COLOR;
        closeButton.style.display = 'none';
        iframe.style.maxHeight = '0';
        iframe.style.opacity = '0';
        iframe.style.top = '0';
        iframe.style.left = '0';
        setTimeout(() => {
          iframe.style.display = 'none';
          iframe.setAttribute('src', '');
        }, this.CSS_ANIMAITON_START_DELAY);
      }
    };

    document.body.appendChild(modalWrapper);
    return (urlArgs: IframeUrlArgs, playerId: string): ModalInfo | null => {
      if (urlArgs !== undefined && playerId !== undefined) {
        const url = this.generateUrl(urlArgs, `/athletes/${playerId}/modal`);
        iframe.setAttribute('src', url);
        modalWrapper.style.display = this.MODAL_WRAPPER_DISPLAY_ACTIVE;
        setTimeout(() => {
          modalBg.style.backgroundColor = this.MODAL_BG_ACTIVE_COLOR;
          closeButton.style.display = 'inline-block';
        }, this.CSS_ANIMAITON_START_DELAY);
        return {
          window: iframe.contentWindow!,
          url
        };
      }
      return null;
    };
  }

  /**
   * URL を受け取り iframe を生成する
   * @param url iframe src url
   */
  static generateIframe(url: string, sizeString: string): HTMLIFrameElement {
    const size = this.generateIframeSize(sizeString);
    const iframe = document.createElement('iframe');
    iframe.setAttribute('sandbox', this.SANDBOX_OPTIONS);
    iframe.setAttribute('src', url);
    iframe.setAttribute('width', size.width);
    iframe.setAttribute('height', size.height);

    return iframe;
  }

  /**
   * クエリパラメータの文字列からシェアIDを取り出す
   *
   * @param search `window.location.search` で取得する値
   */
  static getShareIdFromSearchString(search: string): string | null {
    const match = search.match(RegExp(`${SHARE_ID_QUERY_NAME}=([0-9]+)`));

    return (match?.length ?? 0) > 1 ? match![1] : null;
  }
}
