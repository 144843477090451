import { ThanksInfo } from '~/models/cheer';

/**
 * window.postMessage で送受信するメッセージ enum
 */
export enum UnlimPostMessage {
  OpenModal = 'UNLIM:OPEN_MODAL:',
  CloseModal = 'UNLIM:CLOSE_MODAL',
  CheerDoneUnlim = 'UNLIM:CHEER_DONE:',
  ShareUrlRequest = 'UNLIM:SHARE_URL_REQUEST',
  ShareUrlSend = 'UNLIM:SHARE_URL_SEND:',
  WidgetScrollIn = 'UNLIM:WIDGET_SCROLL_IN',
  BannerLoaded = 'UNLIM:BANNER_LOADED:',
  BannerLoadError = 'UNLIM:BANNER_LOAD_ERROR:'
}

/**
 * メッセージ Origin の指定用 enum
 */
export enum UnlimMessageOrigin {
  EmbedSite = 'unlim-widget-embed-site',
  WidgetSite = 'unlim-widget-site',
  MainSite = 'unlim-main-site'
}

/**
 * window.postMessage に関するユーティリティクラス
 */
export class PostMessageUtil {
  /** メッセージのプレフィックス */
  static readonly MESSAGE_PREFIX = 'UNLIM:';
  /** 値を持つメッセージの正規表現マッチ長 */
  static readonly MESSAGE_HAS_VALUE_REGEXP_MATCH_LENGTH = 2;
  /** 正規表現でマッチした値が格納される配列添字 */
  static readonly REGEXP_VALUE_INDEX = 1;

  /**
   * メッセージの正当性チェック
   * @param event MessageEventオブジェクト
   * @param originSite メッセージの送信元
   * @param embedOrigin iframe の貼り付け元
   */
  static isValidMessage(event: MessageEvent, originSite: UnlimMessageOrigin, embedOrigin?: string): boolean {
    let validOrigin: string = '';
    switch (originSite) {
      case UnlimMessageOrigin.WidgetSite:
        validOrigin = process.env.srcBaseUrl || '';
        break;
      case UnlimMessageOrigin.MainSite:
        validOrigin = process.env.clientWebUrl || '';
        break;
      case UnlimMessageOrigin.EmbedSite:
        if (embedOrigin) {
          validOrigin = embedOrigin!;
        }
        break;
    }
    if (event.origin === validOrigin && event.data?.match) {
      const match = event.data.match(new RegExp(this.MESSAGE_PREFIX));
      return match !== null && match.length > 0;
    } else {
      return false;
    }
  }

  /**
   * モーダルサイトのホスト名を返す
   */
  static getModalSiteHost(): string {
    return process.env.srcBaseUrl || '';
  }

  /**
   * 受信したメッセージイベントが対象のメッセージか判定する
   * @param event MessageEvent
   * @param originSite 送信元のサイト
   * @param targetMessage 判定対象のメッセージ
   */
  static isTargetMessage(event: MessageEvent, originSite: UnlimMessageOrigin, targetMessage: UnlimPostMessage): boolean {
    if (this.isValidMessage(event, originSite)) {
      const matcher = new RegExp(`^${targetMessage}(.*)$`);
      return matcher.test(event.data);
    } else {
      return false;
    }
  }

  /**
   * モーダルを開くためのメッセージを生成する
   * @param athleteId モーダルで開くアスリートの ID
   */
  static generateOpenModalMessage(athleteId: string): string {
    return UnlimPostMessage.OpenModal + athleteId;
  }

  /**
   * バナーページのロード完了メッセージを生成する
   * @param iframeId iframe id
   */
  static generateBannerLoadedMessge(iframeId: string): string {
    return UnlimPostMessage.BannerLoaded + iframeId;
  }

  /**
   * メッセージがモーダルオープンメッセージの場合、対象選手 ID を返す
   * @param event
   */
  static getOpenModalPlayerId(event: MessageEvent): string | null {
    return this.getMessageValue(event, UnlimPostMessage.OpenModal, UnlimMessageOrigin.WidgetSite);
  }

  /**
   * メッセージが BannerLoaded の場合、対象の iframeId を返す
   * @param event MessageEvent
   */
  static getBannerErrorTarget(event: MessageEvent): string | null {
    return this.getMessageValue(event, UnlimPostMessage.BannerLoadError, UnlimMessageOrigin.WidgetSite);
  }

  /**
   * メッセージが BannerLoad の場合、対象の iframeId を返す。
   * @param event MessageEvent
   */
  static getBannerLoadedTarget(event: MessageEvent): string | null {
    return this.getMessageValue(event, UnlimPostMessage.BannerLoaded, UnlimMessageOrigin.WidgetSite);
  }

  /**
   * メッセージが応援完了の場合、対象選手のありがとう情報を返す
   * @param event MessageEvent
   */
  static getCheerDoneThanksInfo(event: MessageEvent): ThanksInfo | null {
    const message = this.getMessageValue(event, UnlimPostMessage.CheerDoneUnlim, UnlimMessageOrigin.MainSite);
    return message ? JSON.parse(message) : null;
  }

  /**
   * SDK からの送付されたシェア URL を取得する
   * @param event MessageEvent
   * @param embedUrl 付与されている URL
   */
  static getShareUrl(event: MessageEvent, embedUrl: string): string | null {
    const result = embedUrl.match(/^(https?:\/{2,}.*?)(?:\/|\?|#|$)/);
    if (result) {
      const originUrl = result[1];
      return this.getMessageValue(event, UnlimPostMessage.ShareUrlSend, UnlimMessageOrigin.EmbedSite, originUrl);
    }
    return null;
  }

  /**
   * SDK から impression のイベントが送付されたか判定する
   * @param event MessageEvent
   * @param embedUrl 付与されている URL
   */
  static isImpressionReceived(event: MessageEvent, embedUrl: string): boolean {
    const result = embedUrl.match(/^(https?:\/{2,}.*?)(?:\/|\?|#|$)/);
    if (result && result.length >= 2) {
      const originUrl = result[1];
      return typeof this.getMessageValue(event, UnlimPostMessage.WidgetScrollIn, UnlimMessageOrigin.EmbedSite, originUrl) === 'string';
    }
    return false;
  }

  /**
   * SDK からモーダルクローズのイベントが送付されたか判定する
   * @param event MessageEvent
   * @param embedUrl 付与されている URL
   */
  static isModalClosedEvent(event: MessageEvent, embedUrl: string): boolean {
    const result = embedUrl.match(/^(https?:\/{2,}.*?)(?:\/|\?|#|$)/);

    if (result && result.length >= 2) {
      const validEvent = event.data === UnlimPostMessage.CloseModal;
      if (validEvent) {
        const originDomain = result[1];
        return this.isValidMessage(event, UnlimMessageOrigin.EmbedSite, originDomain);
      }
    }

    return false;
  }

  /**
   * メッセージが指定するメッセージタイプの場合、付随する値を返す
   * @param event PostMessage で受信した MessageEvent
   * @param messageType 対象のメッセージタイプ
   * @param messageOrigin UnlimMessageOrigin
   * @param embedOrigin iframe の貼り付け元
   */
  private static getMessageValue(event: MessageEvent, messageType: UnlimPostMessage, messageOrigin: UnlimMessageOrigin, embedOrigin?: string): string | null {
    if (this.isValidMessage(event, messageOrigin, embedOrigin)) {
      const matcher = new RegExp(`^${messageType}(.*)$`);
      const result = event.data.match(matcher);
      if (result !== null && result.length >= this.MESSAGE_HAS_VALUE_REGEXP_MATCH_LENGTH) {
        return result[this.REGEXP_VALUE_INDEX];
      }
    }
    return null;
  }
}
